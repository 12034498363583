import React from "react"
import "twin.macro"

// Components
import Layout from "@components/layout"
import Container from "@components/container"
import Seo from "@components/seo"
import SucForm from "@components/sucForm"

// Assets
import logo from "@images/logos/xywav-logo.svg"
import standupChampion from "@images/logos/logo-suc.svg"

const BecomeStandupChampionPage = ({ location }) => {
  return (
    <Layout
      pageBgColour="alabaster-2"
      location={location}
      headerType="narcolepsy"
      footerType="narcolepsy"
      isiBgColour="white"
      footerBgColour="white"
    >
      <Seo
        title="SUC Sign Up Form | XYWAV®"
        description="Interested in sharing your experience taking Xywav and becoming a Standup Champion? Click to learn more and help inspire others."
        location={location}
      />

      <section tw="relative pt-16 md:pt-24 xl:(pt-32)">
        <img
          src={logo}
          alt=""
          width="700px"
          tw="hidden xl:(block absolute right-[-60px] top-5)"
        />
        <Container>
          <h1 tw="font-ffcocon text-4xl leading-[1.25] lg:max-w-[570px] xl:(text-6xl)">
            Share your story, <br tw="sm:hidden lg:block" />
            and you could be&nbsp;a <span tw="sr-only">standup champion</span>
            <img
              src={standupChampion}
              alt=""
              tw="block mt-2.5 max-w-[265px] xl:(max-w-[535px])"
            />
          </h1>
          <p tw="mt-5 text-left pr-6 text-base lg:(max-w-[570px] text-xl)">
            If you are taking XYWAV and would like to help inspire others with
            narcolepsy, please take a moment to share your story. We may use
            some or all of your story on our website or in other materials.
          </p>
        </Container>
      </section>

      <section tw="pb-7 mt-10 md:(max-w-[768px] mx-auto) lg:max-w-[1024px] xl:(pb-[80px] max-w-[1170px])">
        <SucForm />
      </section>
    </Layout>
  )
}

export default BecomeStandupChampionPage
